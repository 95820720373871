import { FC } from 'react'
import { useOrgStore } from '@stores/useOrgStore'
import { OrgsModal } from './OrgsModal/OrgsModal'
import { ButtonElement } from '@components/Common/ButtonElement/ButtonElement'
import KeyIcon from '@static/sidemenu/credentials-icon.svg?react'
import { useUserStore } from '@stores/useUserStore'
import { AppInitResp } from '@services/apis/organization'
import LogoutIcon from '@static/common/logout.svg?react'
import { useHistory } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { FlexContainer } from '@components/Common/FlexContainer/FlexContainer'

type OrgMFAEnforcementModalProps = {
  appInitData: AppInitResp
}

export const OrgMFAEnforcementModal: FC<OrgMFAEnforcementModalProps> = ({ appInitData }) => {
  const { replace } = useHistory()
  const organization = useOrgStore((state) => state.organization)
  const queryClient = useQueryClient()

  const { setShowEnableMFAModal, logout } = useUserStore((state) => ({
    setShowEnableMFAModal: state.setShowEnableMFAModal,
    logout: state.logoutUser,
  }))

  const allowedOrgs = appInitData.organizations.filter(
    (org) => org.id !== organization.id && !org.settings.enforce_mfa,
  )

  return (
    <OrgsModal
      accessibleOrgs={allowedOrgs}
      isSSOAuth={appInitData.is_sso_auth}
      title='Multi-Factor Authentication Required'
      subTitle={`${organization.name}'s workspace enforces MFA for all accounts. To gain access you must first enable MFA for your account.`}
      subSubTitle={
        allowedOrgs.length > 0 && 'Or you can still access the following workspaces where MFA is not enforced'
      }
    >
      <FlexContainer gap='var(--s-xxs)' fullWidth>
        <ButtonElement
          fullWidth
          iconProps={{ icon: LogoutIcon }}
          scaleOnTap
          onClick={() => logout(replace, queryClient)}
        >
          Logout
        </ButtonElement>

        <ButtonElement
          fullWidth
          variant='secondary'
          iconProps={{ icon: KeyIcon }}
          scaleOnTap
          onClick={() => setShowEnableMFAModal(true)}
        >
          Enable MFA
        </ButtonElement>
      </FlexContainer>
    </OrgsModal>
  )
}
