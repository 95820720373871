import { ButtonElement } from '@components/Common/ButtonElement/ButtonElement'
import { FlexContainer } from '@components/Common/FlexContainer/FlexContainer'
import { BoxWrapper } from '@components/SidePanel/BoxWrapper/BoxWrapper'
import { useCopyToClipboard } from '@hooks/useCopyToClipboard'
import { FC } from 'react'

type EnableMFABackupProps = {
  mfaBackupCode: string
  onBackupAcknowledge: () => void
}

export const EnableMFABackup: FC<EnableMFABackupProps> = ({ mfaBackupCode, onBackupAcknowledge }) => {
  const { copyToClipboard } = useCopyToClipboard()

  return (
    <FlexContainer
      direction='column'
      align='center'
      justify='center'
      gap='var(--s-xl)'
      className='enable-mfa'
    >
      <FlexContainer direction='column' gap='var(--s-s)' fullWidth className='enable-mfa__content'>
        <FlexContainer gap='var(--s-xxs)' align='center' fullWidth>
          <BoxWrapper>{mfaBackupCode}</BoxWrapper>

          <ButtonElement
            iconProps={{ icon: 'copy' }}
            style={{ height: '36px' }}
            variant='secondary'
            onClick={() => copyToClipboard(mfaBackupCode)}
            scaleOnTap
          />
        </FlexContainer>

        <ButtonElement variant='secondary' onClick={onBackupAcknowledge} fullWidth scaleOnTap>
          Continue
        </ButtonElement>
      </FlexContainer>
    </FlexContainer>
  )
}
