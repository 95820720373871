import { ComponentProps, FC, useState } from 'react'
import { FlexContainer } from '@components/Common/FlexContainer/FlexContainer'
import ElementLogo from '@static/common/element-logo-full.svg?react'
import { VerifyMFA } from './VerifyMFA/VerifyMFA'
import { AuthContainer } from '@pages/Auth/components/AuthContainer/AuthContainer'
import { EnableMFA } from './EnableMFA/EnableMFA'
import { Typography } from '@components/Common/Typography/Typography'
import { EnableMFABackup } from './EnableMFABackup/EnableMFABackup'
import './MFA.scss'

export enum MFAStep {
  ENABLE = 1,
  BACKUP = 2,
  VERIFY = 3,
}

export type MFAProps = {
  token: string
  step: MFAStep
}

const MFA_STEP_TO_TITLE = {
  [MFAStep.ENABLE]: 'Enable Multi-Factor Authentication',
  [MFAStep.BACKUP]: 'Multi-Factor Authentication Enabled',
  [MFAStep.VERIFY]: 'Verify Multi-Factor Authentication',
}

const typographyProps = { color: 'var(--blue-3)', fontSize: 'var(--text-size-3)', align: 'center' } as const

const MFA_STEP_TO_DESC = {
  [MFAStep.ENABLE]: (
    <Typography {...typographyProps}>
      To continue, use your phone to scan the QR code with Google Authenticator or other authentication apps.
    </Typography>
  ),

  [MFAStep.BACKUP]: (
    <Typography {...typographyProps}>
      Please save the following backup code in a safe place.
      <br />
      You can use this code to access your account should you lose access to your phone.
    </Typography>
  ),

  [MFAStep.VERIFY]: (
    <Typography {...typographyProps}>
      Use an authentication app on your phone and enter the six-digit code to verify your account.
      <br />
      Alternatively, use the backup code you saved.
    </Typography>
  ),
}

type MFAComponentProps = {
  mfaProps: MFAProps
  setMfaProps: (mfaProps: MFAProps) => void
  onBackupAcknowledge: () => void
  backButtonProps: ComponentProps<typeof EnableMFA>['backButtonProps']
}

export const MFA: FC<MFAComponentProps> = ({
  mfaProps,
  setMfaProps,
  onBackupAcknowledge,
  backButtonProps,
}) => {
  const [backupCode, setBackupCode] = useState<string>('')
  const { token, step } = mfaProps

  const ComponentMap = {
    [MFAStep.VERIFY]: <VerifyMFA mfaToken={token} setMfaProps={setMfaProps} />,
    [MFAStep.ENABLE]: (
      <EnableMFA
        mfaToken={mfaProps.token}
        setMfaProps={setMfaProps}
        setBackupCode={setBackupCode}
        backButtonProps={backButtonProps}
      />
    ),
    [MFAStep.BACKUP]: (
      <EnableMFABackup mfaBackupCode={backupCode} onBackupAcknowledge={onBackupAcknowledge} />
    ),
  }

  const MFAComponent = ComponentMap[step]

  return (
    <AuthContainer className='mfa' flexContainerProps={{ direction: 'column', gap: 'var(--s-s)' }}>
      <FlexContainer direction='column' gap='var(--s-s)'>
        <ElementLogo className='auth-container__logo' />

        <FlexContainer direction='column' gap='var(--s-xxxs)' className='mfa__top'>
          <Typography color='var(--blue-4)' fontSize='var(--text-size-3)' fontWeight='500'>
            {MFA_STEP_TO_TITLE[step]}
          </Typography>

          {MFA_STEP_TO_DESC[step]}
        </FlexContainer>
      </FlexContainer>

      {MFAComponent}
    </AuthContainer>
  )
}
