import { TIMEFRAME, TIMEFRAME_TO_STRING, defaultTimeFrame } from '@pages/Dashboard/dashboard-consts'
import { extractQueryParams } from '@utils/global-helpers'
import { FC, SyntheticEvent, useMemo, useState } from 'react'
import { PopoverItem, PopoverMenu } from '../../../Common/PopoverMenu/PopoverMenu'
import { useLocation, useHistory } from 'react-router-dom'
import { ROUTES } from '@enums/routes'
import { TopNavButton } from '../TopNav'
import ChevronDown from '@static/common/chevron-down.svg?react'

export const TimeFrameSelect: FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null)
  const { search } = useLocation()
  const history = useHistory()

  const timeFrame = useMemo(() => {
    const queryParam = extractQueryParams(search)
    return queryParam?.t ? TIMEFRAME_TO_STRING[queryParam.t as string] : TIMEFRAME_TO_STRING[defaultTimeFrame]
  }, [search])

  const handleTimeFrameClick = (e: SyntheticEvent, newTimeFrame: PopoverItem) => {
    if (newTimeFrame === timeFrame) return
    const formattedTimeFrame = newTimeFrame.name.replaceAll(' ', '_').toUpperCase()

    history.push(`${ROUTES.DASHBOARD}?t=${TIMEFRAME[formattedTimeFrame]}`)
    setAnchorEl(null)
  }

  const dropDownList = Object.values(TIMEFRAME_TO_STRING).map((t) => ({ name: t, value: t }))

  return (
    <div className='timeframe-wrapper'>
      <TopNavButton onClick={(e) => setAnchorEl(e.currentTarget)} isActive={!!anchorEl}>
        {timeFrame}
        <ChevronDown className='chevron' />
      </TopNavButton>

      <PopoverMenu
        isOptionActive={(item) => item.name === timeFrame}
        handleCloseMenu={() => setAnchorEl(null)}
        darkMode
        anchorEl={anchorEl}
        options={dropDownList}
        onOptionClick={handleTimeFrameClick}
      />
    </div>
  )
}
