import { ApiJob, ApiJobType } from '@typings/api-job'
import { ApiJobsService } from '@services/apis/api-jobs'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { API_JOB_STATUS } from '@enums/api-jobs'
import { QUERY_KEYS } from '@enums/queries'
import { useCommonStore } from '@stores/useCommonStore'
import { useOrgStore } from '@stores/useOrgStore'
import { useCallback } from 'react'
import { useApiJobsStore } from '@stores/useApiJobsStore'
import { IPRange } from '@typings/whois-data'

const POLL_RATE = 6000

export type StartJobParams =
  | { ids: number[] } // When selecting multiple items on the table
  | { range: IPRange; query: string } // For Netblocks Page
  | { query: string } // Just for filtering with query
  | { integration_id: number } // Integration Panel
  | { status: number[] } // For single item actions

export const useApiJobs = () => {
  const organization = useOrgStore((s) => s.organization)
  const toggleSnackBar = useCommonStore((s) => s.toggleSnackBar)

  const {
    createdJobs,
    setCreatedJobs,
    setEnableJobsQuery,
    setHandledJobIds,
    handledJobIds,
    enableJobsQuery,
  } = useApiJobsStore((s) => ({
    createdJobs: s.createdJobs,
    enableJobsQuery: s.enableJobsQuery,
    handledJobIds: s.handledJobIds,
    setCreatedJobs: s.setCreatedJobs,
    setEnableJobsQuery: s.setEnableJobsQuery,
    setHandledJobIds: s.setHandledJobIds,
  }))

  const onJobFinish = async (finishedJob: ApiJob) => {
    const createdJob = createdJobs.find((j) => j.id === finishedJob.id)
    if (!createdJob)
      return toggleSnackBar({
        message: 'An error occurred while handling the task, please try again later.',
        type: 'error',
      })

    createdJob.onSuccess(finishedJob)
  }

  const { data: jobs = [] } = useQuery({
    queryKey: [QUERY_KEYS.apiJobs, { createdJobs }],
    queryFn: async ({ signal }) => {
      const { jobs } = await ApiJobsService.getJobs({
        orgId: organization.id,
        jobIds: createdJobs.map(({ id }) => id).join(','),
        signal,
      })

      const unhandledSuccessJobIds = []
      const allDone = jobs.every((job, idx) => {
        const isSuccess = job.status === API_JOB_STATUS.SUCCESS
        const isUnhandled = isSuccess && !handledJobIds.includes(job.id)

        if (isUnhandled) {
          unhandledSuccessJobIds.push(job.id)

          if (idx === 0) onJobFinish(job)
          else setTimeout(() => onJobFinish(job), idx * 1750)
        }

        return job.status !== API_JOB_STATUS.PENDING
      })

      if (unhandledSuccessJobIds.length > 0) {
        setHandledJobIds([...handledJobIds, ...unhandledSuccessJobIds])
      }

      if (allDone) setEnableJobsQuery(false)
      return jobs
    },
    placeholderData: keepPreviousData,
    enabled: enableJobsQuery,
    refetchInterval: POLL_RATE,
  })

  async function startJob(props: {
    jobType: ApiJobType
    onJobFinish: (job: ApiJob) => void
    jobParams?: StartJobParams
  }): Promise<void> {
    const { jobType, onJobFinish, jobParams } = props

    try {
      const resp = await ApiJobsService.createJob({
        orgId: organization.id,
        payload: { jobType, jobParams },
      })

      setCreatedJobs([...createdJobs, { id: resp.id, onSuccess: onJobFinish }])
      if (!enableJobsQuery) setEnableJobsQuery(true)
    } catch (error) {
      console.log(error)
      const code = error?.response?.status

      if (code === 429) {
        toggleSnackBar({
          message: 'A pending task already exists for this action, please try again later',
          type: 'error',
        })
        return
      }

      toggleSnackBar({
        message: 'An error occurred while creating task, please try again later',
        type: 'error',
      })
    }
  }

  const getIsJobPending = useCallback(
    (jobType: ApiJobType) => {
      return jobs.some((job) => job.type === jobType && job.status === API_JOB_STATUS.PENDING)
    },
    [jobs],
  )

  return { startJob, getIsJobPending, jobs }
}
