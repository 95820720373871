import { FC, useEffect, useState } from 'react'
import { MFAProps, MFAStep } from '../MFA'
import { FlexContainer } from '@components/Common/FlexContainer/FlexContainer'
import { ButtonElement } from '@components/Common/ButtonElement/ButtonElement'
import { extractUIError } from '@utils/global-helpers'
import { useCommonStore } from '@stores/useCommonStore'
import Loader from '@components/Common/Loader'
import { useQuery } from '@tanstack/react-query'
import { BoxWrapper } from '@components/SidePanel/BoxWrapper/BoxWrapper'
import { QUERY_KEYS } from '@enums/queries'
import KeyIcon from '@static/sidemenu/credentials-icon.svg?react'
import { StyledSeperatorText } from '@components/Common/StyledSeperatorText/StyledSeperatorText'
import { Typography } from '@components/Common/Typography/Typography'
import { VerifyMFAInputs } from '../VerifyMFAInputs/VerifyMFAInputs'
import { MFAService } from '@services/apis/mfa'
import { useCopyToClipboard } from '@hooks/useCopyToClipboard'
import './EnableMFA.scss'

type EnableMFAProps = {
  mfaToken: string
  setMfaProps: (props: MFAProps) => void
  setBackupCode: (code: string) => void
  backButtonProps: {
    onClick: () => void
    text: string
  }
}

export const EnableMFA: FC<EnableMFAProps> = ({ mfaToken, setMfaProps, setBackupCode, backButtonProps }) => {
  const [actionLoading, setActionLoading] = useState<boolean>(false)
  const [inputValues, setInputValues] = useState<string[]>(Array.from({ length: 6 }, () => ''))
  const [apiError, setApiError] = useState<string | null>(null)

  const { copyToClipboard } = useCopyToClipboard()

  const toggleSnackBar = useCommonStore((state) => state.toggleSnackBar)

  const mfaDataQuery = useQuery({
    queryKey: [QUERY_KEYS.getEnableMFAData],
    queryFn: async () => await MFAService.getEnableMfaData(mfaToken),
    meta: {
      errorCallback: (e) => {
        const msg = extractUIError(e)
        toggleSnackBar({
          message: msg ?? 'An error occured while enabling MFA please try again later',
          type: "error"
        })
      },
    },
  })

  const onEnableMFA = async () => {
    setActionLoading(true)
    try {
      const data = await MFAService.enableMFA({
        token: mfaToken,
        otp_value: inputValues.join(''),
      })
      setBackupCode(data.mfa_backup_secret)
      setMfaProps({ token: mfaToken, step: MFAStep.BACKUP })
    } catch (error) {
      console.log(error)
      const msg = extractUIError(error)
      if (msg) return setApiError(msg)

      toggleSnackBar({
        message: 'An error occured while enabling MFA please try again later',
        type: "error"
      })
    } finally {
      setActionLoading(false)
    }
  }

  // Auto enable MFA if all inputs are filled
  useEffect(() => {
    if (inputValues.every((v) => v)) {
      onEnableMFA()
    }
  }, [inputValues])

  const inputValue = mfaDataQuery.data?.mfa_secret ?? '***********************************'

  return (
    <FlexContainer
      direction='column'
      align='center'
      justify='center'
      gap='var(--s-ml)'
      className='enable-mfa'
    >
      <div className='enable-mfa__qr'>
        {mfaDataQuery.isSuccess && (
          <img
            alt='QR Code'
            src={`data:image/png;base64,${mfaDataQuery.data.qr_code_image}`}
            className='enable-mfa__qr__img'
          />
        )}
      </div>

      <FlexContainer direction='column' gap='var(--s-s)' fullWidth className='enable-mfa__content'>
        <FlexContainer direction='column' gap='var(--s-s)' fullWidth>
          <Typography color={'var(--blue-3)'} fontSize={'var(--text-size-2)'}>
            Or enter the code manually into your authenticator app
          </Typography>

          <FlexContainer gap='var(--s-xxs)' align='center' fullWidth>
            <BoxWrapper>{inputValue}</BoxWrapper>

            <ButtonElement
              iconProps={{ icon: 'copy' }}
              style={{ height: '36px' }}
              variant='secondary'
              onClick={() => copyToClipboard(inputValue)}
              scaleOnTap
            />
          </FlexContainer>
        </FlexContainer>

        <FlexContainer direction='column' gap='var(--s-s)' fullWidth align='flex-start'>
          <StyledSeperatorText>
            <Typography color={'var(--blue-3)'} fontSize={'var(--text-size-2)'}>
              Enter the 6 digit code from your authenticator app
            </Typography>
          </StyledSeperatorText>

          <VerifyMFAInputs setInputValues={setInputValues} inputValues={inputValues} />
          {apiError && (
            <Typography fontSize={'var(--text-size-3)'} color='var(--red-3)' fontWeight={500}>
              {apiError}
            </Typography>
          )}
        </FlexContainer>

        <FlexContainer gap='var(--s-xxs)' align='center' fullWidth direction='column'>
          <ButtonElement
            variant='secondary'
            onClick={onEnableMFA}
            iconProps={{ icon: KeyIcon }}
            disabled={actionLoading || mfaDataQuery.isLoading || inputValues.some((v) => !v)}
            fullWidth
            scaleOnTap
          >
            Enable MFA
          </ButtonElement>

          <ButtonElement onClick={backButtonProps.onClick} fullWidth scaleOnTap>
            {backButtonProps.text}
          </ButtonElement>
        </FlexContainer>
      </FlexContainer>

      <Loader loading={actionLoading || mfaDataQuery.isLoading} />
    </FlexContainer>
  )
}
