import { App } from './pages/App/App'
import { createRoot } from 'react-dom/client'
import i18n from './i18n/i18n'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient } from '@libs/useQuery'
import { QueryClientProvider } from '@tanstack/react-query'
import { I18nextProvider } from 'react-i18next'
import { browserTracingIntegration, replayIntegration, init as SentryInit } from '@sentry/react'
import './styles/core/_index.scss'

class DebuggedBrowserRouter extends BrowserRouter {
  constructor(props) {
    super(props)

    if (import.meta.env.MODE === 'production') return

    // @ts-ignore
    console.log(`Initial path is: ${this.history.location.pathname}`)

    // @ts-ignore
    this.history.listen((location, action) => {
      // @ts-ignore
      console.log(`History changed => Path: "${location.pathname}"; Action: "${action?.toLowerCase()}"`)
    })
  }
}

SentryInit({
  enabled: import.meta.env.MODE === 'production',
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.MODE,
  integrations: [browserTracingIntegration(), replayIntegration()],
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  replaysSessionSampleRate: 1.0, //  Capture 100% of the sessions
})

const root = createRoot(document.getElementById('root'))

root.render(
  <I18nextProvider i18n={i18n}>
    <QueryClientProvider client={QueryClient}>
      <DebuggedBrowserRouter>
        <App />
      </DebuggedBrowserRouter>
    </QueryClientProvider>
  </I18nextProvider>,
)
