import React, { useRef, useState, useEffect, FC, ReactElement } from 'react'
import TooltipElement from '../TooltipElement'
import { ITooltipElement } from '../TooltipElement/TooltipElement'
interface IOverflowTipProps {
  children: ReactElement | string
  style?: React.CSSProperties
  customClassName?: string
  tooltipProps?: Omit<ITooltipElement, 'title' | 'children'>
}

export const OverflowTip: FC<IOverflowTipProps> = ({ children, style, tooltipProps, customClassName }) => {
  const [isOverflowed, setIsOverflow] = useState(false)
  const textElementRef = useRef(null)

  useEffect(() => {
    const { current } = textElementRef || {}
    if (!current) return

    const handleOverflow = () => setIsOverflow(current.scrollWidth > current.clientWidth)

    handleOverflow()
  }, [])

  const text = typeof children === 'string' ? children : children?.props?.children

  return (
    <TooltipElement
      title={text ?? ''}
      disableHoverListener={!isOverflowed}
      enterDelay={300}
      {...tooltipProps}
    >
      <div
        ref={textElementRef}
        className={customClassName}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          ...style,
        }}
      >
        {children}
      </div>
    </TooltipElement>
  )
}
