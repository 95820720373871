import { useCallback, useState } from 'react'

// Fallback function for older browsers
function oldSchoolCopy(value: string) {
  const textarea = document.createElement('textarea')
  textarea.value = value
  document.body.appendChild(textarea)
  textarea.select()
  document.execCommand('copy')
  document.body.removeChild(textarea)
}

export const useCopyToClipboard = () => {
  const [state, setState] = useState<string | null>(null)
  const [isCopied, setIsCopied] = useState<boolean>(false)

  const copyToClipboard = useCallback((value: string, duration?: number) => {
    if (isCopied) return

    const handleCopy = async () => {
      try {
        if (navigator?.clipboard?.writeText) {
          await navigator.clipboard.writeText(value)
          setState(value)
          setIsCopied(true)

          // Reset after the optional duration
          if (duration) {
            setTimeout(() => {
              setState(null)
              setIsCopied(false)
            }, duration)
          }
        } else {
          throw new Error('writeText not supported')
        }
      } catch (e) {
        console.log('Clipboard writeText failed:', e)
        oldSchoolCopy(value)
        setState(value)
        setIsCopied(true)

        // Reset after the optional duration
        if (duration) {
          setTimeout(() => {
            setState(null)
            setIsCopied(false)
          }, duration)
        }
      }
    }

    handleCopy()
  }, [])

  return { copiedValue: state, isCopied, copyToClipboard }
}
