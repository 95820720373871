import { ComponentProps, ReactNode, SyntheticEvent, useMemo, useState } from 'react'
import TooltipElement from '@components/Common/TooltipElement'
import { MenuItem, Popover, PopoverProps } from '@mui/material'
import clsx from 'clsx'
import ChevronRight from '@static/common/chevron-right.svg?react'
import StyledTextField from '@components/Common/StyledTextField'
import './PopoverMenu.scss'

export type PopoverItem<TValue = any> = {
  name: string
  value: TValue
  icon?: ReactNode
  tooltipProps?: Omit<ComponentProps<typeof TooltipElement>, 'children'>
  disabled?: boolean
}

type PopoverMenuProps<TValue> = {
  options: PopoverItem<TValue>[]
  onOptionClick: (e: SyntheticEvent, item: PopoverItem<TValue>) => void // Either this or onClick in the item
  handleCloseMenu: (e: SyntheticEvent) => void
  anchorEl: HTMLElement

  withSearch?: boolean
  isOptionActive?: (item: PopoverItem<TValue>) => boolean
  showChevronOnOption?: (item: PopoverItem<TValue>) => boolean
  darkMode?: boolean
  beforeListChildren?: ReactNode
  afterListChildren?: ReactNode
  useAnchorWidth?: boolean
  inputProps?: Partial<ComponentProps<typeof StyledTextField>>
} & Partial<PopoverProps>

export const PopoverMenu = <TValue,>({
  options = [],
  onOptionClick,
  anchorEl,
  handleCloseMenu,

  withSearch,
  useAnchorWidth,
  darkMode,
  beforeListChildren,
  afterListChildren,
  showChevronOnOption = () => false,
  isOptionActive = () => false,
  inputProps,
  ...popoverProps
}: PopoverMenuProps<TValue>) => {
  const [searchValue, setSearchValue] = useState<string>('')

  const {
    classes = {},
    transitionDuration = 250,
    anchorOrigin = { vertical: 'bottom', horizontal: 'left' },
    transformOrigin = { vertical: 'top', horizontal: 'left' },
    ...rest
  } = popoverProps || {}

  const filteredOptions = useMemo(() => {
    if (!withSearch) return options

    const filtered = options.filter((item) => item.name.toLowerCase().includes(searchValue.toLowerCase()))
    return filtered
  }, [searchValue, withSearch, options])

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchVal = e.target.value
    setSearchValue(searchVal)
  }

  const handleClose = (e: SyntheticEvent) => {
    e.stopPropagation()
    handleCloseMenu(e)

    if (withSearch) {
      setTimeout(() => setSearchValue(''), typeof transitionDuration === 'number' ? transitionDuration : 0)
    }
  }

  return (
    <Popover
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      classes={{
        root: clsx('popover-menu', classes.root, { dark: darkMode }),
        paper: clsx('popover-menu__paper', classes.paper),
      }}
      slotProps={{
        paper: {
          style: {
            width: useAnchorWidth ? anchorEl?.clientWidth : 'auto',
            minWidth: useAnchorWidth && 'unset',
          },
        },
      }}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      transitionDuration={transitionDuration}
      {...rest}
    >
      {beforeListChildren}

      {withSearch && (
        <StyledTextField
          autoFocus
          value={searchValue}
          onChange={handleSearch}
          placeholder='Search'
          withStartSearchIcon
          fullWidth
          cssVariablesProps={{
            borderRadius: 'var(--br-md)',
          }}
          {...inputProps}
        />
      )}

      {filteredOptions.length > 0 && (
        <div className='popover-menu__list'>
          {filteredOptions.map((option, idx) => {
            const Icon = option.icon

            return (
              <TooltipElement {...option.tooltipProps} key={idx}>
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation()
                    onOptionClick(e, option)
                  }}
                  disabled={option.disabled}
                  selected={isOptionActive(option)}
                  classes={{ root: 'popover-menu__item', selected: 'popover-menu__item--selected' }}
                >
                  {Icon && <div className='popover-menu__item__icon'>{Icon}</div>}
                  {option.name}

                  {showChevronOnOption(option) && <ChevronRight className='popover-menu__item__chevron' />}
                </MenuItem>
              </TooltipElement>
            )
          })}
        </div>
      )}

      {afterListChildren}
    </Popover>
  )
}
