export const ISSUE_STATUS = {
  open: 1,
  triaged: 2,
  closed: 3,
} as const

export type TIssueStatus = (typeof ISSUE_STATUS)[keyof typeof ISSUE_STATUS]

export const ISSUE_STATUS_TO_LABEL = {
  [ISSUE_STATUS.open]: 'open',
  [ISSUE_STATUS.triaged]: 'triaged',
  [ISSUE_STATUS.closed]: 'closed',
}

export const ISSUE_CLOSURE_REASON = {
  resolved: 1,
  false_positive: 2,
  irrelevant: 3,
  auto_closed: 5,
}

export type TClosureReason = (typeof ISSUE_CLOSURE_REASON)[keyof typeof ISSUE_CLOSURE_REASON]

export const ISSUE_REASON_TO_LABEL = {
  [ISSUE_CLOSURE_REASON.false_positive]: 'False positive',
  [ISSUE_CLOSURE_REASON.irrelevant]: 'Irrelevant',
  [ISSUE_CLOSURE_REASON.resolved]: 'Resolved',
  [ISSUE_CLOSURE_REASON.auto_closed]: 'Auto Closed',
}

enum ISSUE_TYPE_TO_LABEL {
  SUBDOMAIN_TAKEOVER = 'Subdomain Takeover',
  APPLICATION_EXPOSURES = 'Application Exposures',
  WEB_APP_MISCONFIG = 'Web Application Misconfigurations',
  CVES = 'Prioritized CVEs',
  INF_MISCONFIG = 'Infrastructure Misconfigurations',
  CLOUD_EXPOSURES = 'Cloud Exposures',
  CERTIFICATE_MANAGEMENT = 'Certificate Management',
  FILE_EXPOSURES = 'File Exposures',
  ADMIN_INTERFACE = 'Exposed Admin Interface',
}

export const ISSUE_TYPE_TO_COLOR = {
  [ISSUE_TYPE_TO_LABEL.CERTIFICATE_MANAGEMENT]: '#3EDE90',
  [ISSUE_TYPE_TO_LABEL.WEB_APP_MISCONFIG]: '#007C8E',
  [ISSUE_TYPE_TO_LABEL.SUBDOMAIN_TAKEOVER]: '#454395',
  [ISSUE_TYPE_TO_LABEL.APPLICATION_EXPOSURES]: '#5BECD2',
  [ISSUE_TYPE_TO_LABEL.CVES]: '#253146',
  [ISSUE_TYPE_TO_LABEL.FILE_EXPOSURES]: '#ED788F',
  [ISSUE_TYPE_TO_LABEL.ADMIN_INTERFACE]: '#D5B4FF',
  [ISSUE_TYPE_TO_LABEL.INF_MISCONFIG]: '#B8337B',
  [ISSUE_TYPE_TO_LABEL.CLOUD_EXPOSURES]: '#0CD5E2',
}

export const ISSUE_TYPE_DISPLAY = {
  [ISSUE_TYPE_TO_LABEL.SUBDOMAIN_TAKEOVER]: 'Subdomain Takeovers',
  [ISSUE_TYPE_TO_LABEL.APPLICATION_EXPOSURES]: 'Application Exposures',
  [ISSUE_TYPE_TO_LABEL.WEB_APP_MISCONFIG]: 'Web App Misconfigs',
  [ISSUE_TYPE_TO_LABEL.CVES]: 'Prioritized CVEs',
  [ISSUE_TYPE_TO_LABEL.CERTIFICATE_MANAGEMENT]: 'Certificates Management',
  [ISSUE_TYPE_TO_LABEL.FILE_EXPOSURES]: 'File Exposures',
  [ISSUE_TYPE_TO_LABEL.ADMIN_INTERFACE]: 'E.Admin Interface',
  [ISSUE_TYPE_TO_LABEL.INF_MISCONFIG]: 'Infrastructure Misconfigs',
  [ISSUE_TYPE_TO_LABEL.CLOUD_EXPOSURES]: 'Cloud Exposures',
}

export const ISSUE_SEVERITY = {
  critical: 1,
  high: 2,
  medium: 3,
  low: 4,
  info: 5,
} as const

export type TIssueSeverity = (typeof ISSUE_SEVERITY)[keyof typeof ISSUE_SEVERITY]

export const ISSUE_SEVERITY_TO_LABEL = {
  [ISSUE_SEVERITY.info]: 'info',
  [ISSUE_SEVERITY.low]: 'low',
  [ISSUE_SEVERITY.medium]: 'medium',
  [ISSUE_SEVERITY.high]: 'high',
  [ISSUE_SEVERITY.critical]: 'critical',
} as const

export const ISSUE_RETEST_STATUS = {
  pending: 1,
  secure: 2,
  vulnerable: 3,
  failed: 4,
}

export type TRestestStatus = (typeof ISSUE_RETEST_STATUS)[keyof typeof ISSUE_RETEST_STATUS]

export const ISSUE_RETEST_STATUS_TO_COLOR = {
  [ISSUE_RETEST_STATUS.pending]: 'rgb(237 171 0)',
  [ISSUE_RETEST_STATUS.secure]: 'rgb(43 201 172)',
  [ISSUE_RETEST_STATUS.vulnerable]: '#DB575E',
  [ISSUE_RETEST_STATUS.failed]: '#DB575E',
}

export const ISSUE_RETEST_STATUS_TO_BG = {
  [ISSUE_RETEST_STATUS.pending]: 'rgb(247 238 210)',
  [ISSUE_RETEST_STATUS.secure]: 'rgb(210 255 247 / 62%)',
  [ISSUE_RETEST_STATUS.vulnerable]: '#F8DDDF',
  [ISSUE_RETEST_STATUS.failed]: '#F8DDDF',
}

export const ISSUE_VIEWS = {
  TABLE: 'table',
  LIST: 'list',
} as const

export type IssueViewType = (typeof ISSUE_VIEWS)[keyof typeof ISSUE_VIEWS]

export const issueStatuses = [
  {
    value: ISSUE_STATUS.open,
    name: 'Open',
  },
  {
    value: ISSUE_STATUS.triaged,
    name: 'Triaged',
  },
  {
    value: ISSUE_STATUS.closed,
    name: 'Closed',
  },
]

export const issueClosureReasons = [
  {
    value: ISSUE_CLOSURE_REASON.resolved,
    name: 'Resolved',
  },
  {
    value: ISSUE_CLOSURE_REASON.false_positive,
    name: 'False Positive',
  },
  {
    value: ISSUE_CLOSURE_REASON.irrelevant,
    name: 'Irrelevant',
  },
]
