import { Organization } from '@typings/organization'
import { DEFAULT_APP_ROUTES } from '@enums/routes'
import { redirectToWorkSpace } from '@utils/global-helpers'
import React, { FC, SyntheticEvent, useState } from 'react'
import { PopoverMenu } from '../../../Common/PopoverMenu/PopoverMenu'
import loadable from '@loadable/component'
import Plus from '@static/common/plus.svg?react'
import { CustomLink } from '@components/Common/CustomLink/CustomLink'
import { useOrgStore } from '@stores/useOrgStore'
import { BUCKETS } from '@enums/generics'
import { TopNavButton } from '../TopNav'
import UiImage from '@components/Common/UiImage'
import ChevronDown from '@static/common/chevron-down.svg?react'
import { Typography } from '@components/Common/Typography/Typography'
import { PopoverItem } from '../../../Common/PopoverMenu/PopoverMenu'
import { useUserStore } from '@stores/useUserStore'
import { AppInitResp } from '@services/apis/organization'
import { TooltipProps } from '@mui/material'

type TopNavOrgsProps = {
  appInitData: AppInitResp
}

export const TopNavOrgs: FC<TopNavOrgsProps> = ({ appInitData }) => {
  const [orgMenuAnchor, setOrgMenuAnchor] = useState<HTMLElement>(null)

  const accessibleOrgs = appInitData.organizations || []

  const { isAdmin, user, setShowEnableMFAModal } = useUserStore((state) => ({
    isAdmin: state.isAdmin,
    user: state.user,
    setShowEnableMFAModal: state.setShowEnableMFAModal,
  }))

  const organization = useOrgStore((state) => state.organization)

  const mappedOrgs = React.useMemo(() => {
    return accessibleOrgs.map((org) => {
      const isDisabled = org.settings.enforce_mfa && !user.mfa_enabled && !appInitData.is_sso_auth

      return {
        name: org.name,
        value: org,
        disabled: isDisabled,
        tooltipProps: isDisabled && {
          title: (
            <Typography>
              {`${org.name} enforces MFA. Please `}
              <button
                style={{ color: 'var(--tertiary-1)', fontWeight: 600 }}
                onClick={() => setShowEnableMFAModal(true)}
              >
                Enable
              </button>

              {` MFA in order to gain access.`}
            </Typography>
          ),
          enterDelay: 400,
          placement: 'right' as TooltipProps['placement'],
        },
      }
    })
  }, [accessibleOrgs, user.mfa_enabled])

  const handleOrgsMenuClick = (e: SyntheticEvent, opt: PopoverItem<Organization>) => {
    if (opt.value.id === organization.id) return
    redirectToWorkSpace(opt.value.workspace)
  }

  return (
    <>
      <TopNavButton
        className='top-nav__orgs'
        isActive={!!orgMenuAnchor}
        disabled={!isAdmin && accessibleOrgs.length <= 1}
        onClick={(e) => setOrgMenuAnchor(e.currentTarget)}
      >
        {organization.logo_url && (
          <UiImage
            load='eager'
            src={`${BUCKETS.orgIcons}/${organization.logo_url}`}
            alt={organization.name}
            imgStyle={{ borderRadius: '50%' }}
            customClass='top-nav__orgs--logo'
          />
        )}
        {organization.name}

        {accessibleOrgs.length > 1 && <ChevronDown className='chevron' />}
      </TopNavButton>

      <PopoverMenu
        withSearch={mappedOrgs.length > 1}
        isOptionActive={(item) => item.value.id === organization.id}
        transformOrigin={{ horizontal: 0, vertical: -4 }}
        handleCloseMenu={() => setOrgMenuAnchor(null)}
        anchorEl={orgMenuAnchor}
        classes={{ paper: 'orgs-menu__paper' }}
        options={mappedOrgs}
        darkMode
        onOptionClick={handleOrgsMenuClick}
        inputProps={{ placeholder: 'Search Tenants' }}
        afterListChildren={
          isAdmin && (
            <CustomLink
              onMouseOver={() =>
                loadable(() => import('@pages/AddOrg/components/AddOrgEnvironmentForm')).preload()
              }
              className='orgs-menu__paper--cta'
              to={DEFAULT_APP_ROUTES.ADD_ORG}
              onClick={() => setOrgMenuAnchor(null)}
            >
              <Plus />
              <span>Create New Tenant</span>
            </CustomLink>
          )
        }
      />
    </>
  )
}
