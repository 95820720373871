import { FC } from 'react'
import OrgIcon from '@static/sidemenu/organizations-icon.svg?react'
import { FlexContainer } from '@components/Common/FlexContainer/FlexContainer'
import { Organization } from '@typings/organization'
import { Typography } from '@components/Common/Typography/Typography'
import { OrgModalItem } from './OrgModalItem/OrgModalItem'
import { EnableMFAModal } from '@components/Common/EnableMFAModal/EnableMFAModal'
import './OrgsModal.scss'
import { StyledSeperatorText } from '@components/Common/StyledSeperatorText/StyledSeperatorText'

type OrgsModalProps = {
  isSSOAuth: boolean
  accessibleOrgs: Organization[]
  title: string
  subTitle: string
  subSubTitle?: string
  children?: React.ReactNode
}

export const OrgsModal: FC<OrgsModalProps> = ({
  accessibleOrgs,
  isSSOAuth,
  title,
  subTitle,
  subSubTitle,
  children,
}) => {
  return (
    <>
      <FlexContainer direction='column' align='center' justify='center' style={{ height: '100%' }}>
        <FlexContainer direction='column' className='orgs-modal' align='flex-start' gap='var(--s-sm)'>
          <FlexContainer className='orgs-modal__header' gap='var(--s-xxxs)' align='flex-start'>
            <OrgIcon />
            <Typography fontWeight={500} fontSize='var(--t-normal)'>
              {title}
            </Typography>
          </FlexContainer>

          <Typography color='var(--blue-3)' fontSize='var(--t-medium)' className='orgs-modal__subheader'>
            {subTitle}
          </Typography>

          {children}

          {subSubTitle && (
            <StyledSeperatorText>
              <Typography color={'var(--blue-3)'} fontSize={'var(--t-medium)'}>
                {subSubTitle}
              </Typography>
            </StyledSeperatorText>
          )}

          {accessibleOrgs.length > 0 && (
            <FlexContainer
              gap='var(--s-xxs)'
              direction='column'
              className='orgs-modal__list'
              fullWidth
              justify='flex-start'
            >
              {accessibleOrgs.map((org) => (
                <OrgModalItem key={org.id} orgItem={org} isSSOAuth={isSSOAuth} />
              ))}
            </FlexContainer>
          )}
        </FlexContainer>
      </FlexContainer>

      <EnableMFAModal />
    </>
  )
}
