import { CSSProperties, forwardRef } from 'react'

type IUserCircleProps = {
  fullName: string
  color?: string
} & React.HTMLAttributes<HTMLDivElement>

const getInitials = (fullName: string) => {
  const initials = fullName
    .split(' ')
    .map((el) => el[0])
    .join('')
    .toUpperCase()

  return initials.slice(0, 2)
}

export const UserCircle = forwardRef<HTMLDivElement, IUserCircleProps>(
  ({ fullName, color, ...props }, ref) => {
    return (
      <div ref={ref} {...props} className='user-circle' style={{ '--clr': color } as CSSProperties}>
        <span>{getInitials(fullName)}</span>
      </div>
    )
  },
)
